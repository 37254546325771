
<style scoped lang="less">
.c_course_video {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  min-height: 700px;
  .stu-module-header {
    margin-bottom: 20px;
  }
  .c_course_video_group {
    position: relative;
    @indexWidth: 100px;
    @titlePd: 15px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: @indexWidth;
      border: 1px solid #cccccc;
      height: 100%;
    }
    .items {
      .header {
        background: #f0f5ff;
        display: flex;
        align-items: center;
        height: 46px;
        line-height: 46px;
        cursor: pointer;
        color: @fontColor;
        div {
          font-weight: 500;
        }
        .index {
          width: @indexWidth;
          text-align: center;
        }
        .title {
          padding-left: @titlePd;
        }
        &:hover {
          background: #e4ecff;
        }
      }
    }
    .items_list {
      padding-left: @indexWidth + @titlePd;
      padding-bottom: 10px;
      li {
        margin-left: @titlePd * 2;
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid rgb(238, 238, 238);
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          color: @mainColor;
        }
      }
    }
  }
  .video-player {
    margin-top: 20px;
  }
}
</style>
<template>
  <div class="c_course_video">
    <div class="stu-module-header">
      <div class="stu-module-title">课程视频</div>
    </div>

    <div v-loading="loading" style="min-height: 300px">
      <div v-if="data.length" class="c_course_video_group">
        <div class="items" v-for="(item, index) in data" :key="index">
          <div class="header">
            <div class="index">{{ index + 1 }}</div>
            <div class="title">{{ item.chapterName }}</div>
          </div>
          <ul class="items_list">
            <li v-for="(c, i) in item.childList" :key="i" @click="open(c, i)">
              {{ c.chapterName }}
            </li>
          </ul>
        </div>
      </div>
      <div v-else style="text-align: center; margin-top: 30px">暂无视频</div>
    </div>

    <el-dialog
      :title="selectRow.chapterName"
      :visible.sync="selectRow.dialog"
      width="40%"
    >
      <el-button type="primary" :loading="download_loading" @click="download(selectRow.chapterName)"
        >下载</el-button
      >
      <video-player
        v-if="selectRow.teachingType === 0"
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>
      <div v-else-if="selectRow.teachingType === 1">
        <el-image :src="selectRow.teachingUrl"></el-image>
      </div>
      <div v-else>
        <!-- 0视频 1图片 2在线作业 3外部链接  4其他 -->
        <span>{{
          selectRow.teachingType === 2
            ? "在线作业："
            : selectRow.teachingType === 3
            ? "外部链接"
            : "其他"
        }}</span>
        {{ selectRow.teachingUrl }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { QueryCourseResourceById, TopicDownloadById } from "@/libs/api/topic";
export default {
  name: "c_course_video",
  data() {
    return {
      loading: false,
      data: [],

      selectRow: {
        chapterName: null,
        dialog: false,
      },
      download_loading: false,
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "http://www.html5videoplayer.net/videos/madagascar3.mp4", //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  computed: {
    course() {
      return this.$store.getters.getStuMyCourse;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      QueryCourseResourceById({ courseId: this.course.id })
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    open(row, index) {
      if (row) {
        this.playerOptions.sources[0].src = row.teachingUrl;
        this.selectRow = {
          ...row,
          dialog: true,
        };
      }
    },

    download(fileName) {
      if (this.selectRow) {
        this.download_loading = true;
        TopicDownloadById({ id: this.selectRow.id })
          .then((res) => {
            this.download_loading = false;
            let blob = new Blob([res]);
            let el = document.createElement("a");
            let href = window.URL.createObjectURL(blob);
            el.href = href;
            el.setAttribute('download',fileName+".mp4");
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            window.URL.revokeObjectURL(href);
          })
          .catch(() => {
            this.download_loading = false;
          });
      }
    },
  },
};
</script>
